import React, { useState, useEffect, useRef } from 'react'
import './Spinner.less'
import classnames from 'classnames'

const Spinner = (props) => {
  const [opaque, setopaque] = useState(false)
  const [visible, setvisible] = useState(false)
  const delay = props.fadeDuration || 500
  const fadeDuration = props.fadeDuration || 500
  const debounce = useRef(null)

  const setLoader = () => {
    if (props.show === true && visible === false) {
      if (debounce.current) return
      debounce.current = setTimeout(() => {
        setvisible(true)
        setTimeout(() => {
          setopaque(true)
        })
      }, delay)
    }
    if (props.show === false && opaque === true) {
      setopaque(false)
      setTimeout(() => {
        setvisible(false)
      }, fadeDuration)
    }
    if (props.show === false && debounce.current) {
      clearTimeout(debounce.current)
      debounce.current = null
    }
  }

  useEffect(() => {
    setLoader()
    return () => {
      clearTimeout(debounce.current)
      debounce.current = null
    }
  }, [props.show])

  return (
    <div
      className={classnames('loader-container', {
        active: visible,
        opaque: opaque,
      })}
      style={{ transition: `opacity ${fadeDuration}ms ease` }}
    >
      <div className='backdrop'></div>
      <div className='loader'>Loading...</div>
    </div>
  )
}
export default Spinner
