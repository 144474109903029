import React, { useState, useEffect, useRef } from "react"
import Spinner from "../UI/Spinner/Spinner"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus, faSync, faFileImport, faFileExport } from "@fortawesome/free-solid-svg-icons"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Tooltip from "react-bootstrap/Tooltip"
import Nav from "react-bootstrap/Nav"
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"
import "./Routing.less"
import AddRouteModal from "./AddRouteModal"
import AddDomainModal from "./AddDomainModal"
import { useSnackbar } from "../../contexts/snackbarContext"
import _, { isBuffer } from "lodash"
import ConfirmModal from "../UI/ConfirmModal/ConfirmModal"
import { useConfig } from "../../contexts/configContext"
import { isNonEmptyArray, isNonEmptyObject, isNonEmptyString, removeAllButFields, uuidFromCode } from "../../utils"
import useHttpApi from "../useHttpApi"
import ListTable from "../SharedComponents/ListTable"
import { checkDomainImportedData, checkRoutingImportedData } from "../../importErrors"

const RoutingTable = (props) => {
  const [loading, setloading] = useState(true)
  const [tab, settab] = useState("routing")
  const [inRoutes, setinRoutes] = useState(null)
  const [domains, setdomains] = useState(null)
  const [whitelist, setwhitelist] = useState(null)
  const [routesToShow, setroutesToShow] = useState(null)
  const [routeModal, setrouteModal] = useState(false)
  const [domainModal, setdomainModal] = useState(false)
  const [openConfirmModal, setopenConfirmModal] = useState(false)
  const [openImportModal, setopenImportModal] = useState(false)
  const [fileToBeUploaded, setfileToBeUploaded] = useState(null)
  const [uploadErrors, setuploadErrors] = useState([])

  const config = useConfig()
  const snackbar = useSnackbar()
  const httpApi = useHttpApi()

  const inputRef = useRef(null)

  const seppDomain = config.configInfo.dnsName
  const namespace = config.namespace

  const routeHeader = [
    {
      title: "Remote Domain",
      field: "domain_name",
      size: "28%",
    },
    {
      title: "Direction",
      field: "dialogue_mode",
      size: "28%",
    },
    {
      title: "Dialogue Mode",
      field: "direction",
      size: "28%",
    },
    {
      title: "MFW",
      field: "mfw",
      size: "28%",
    },
    {
      title: "",
      field: "",
      size: "4%",
      type: "delete",
    },
    {
      title: "",
      field: "",
      size: "4%",
      type: "show",
    },
  ]

  const domainHeader = [
    {
      title: "Name",
      field: "domain_name",
      size: "10%",
    },
    {
      title: "Domain",
      field: "dns",
      size: "15%",
    },
    {
      title: "Is Hub",
      field: "is_hub",
      size: "6%",
    },
    {
      title: "Address 1",
      field: "address_1",
      size: "11%",
    },
    {
      title: "Address 2",
      field: "address_2",
      size: "11%",
    },
    {
      title: "Address 3",
      field: "address_3",
      size: "11%",
    },
    {
      title: "Address 4",
      field: "address_4",
      size: "11%",
    },
    {
      title: "SNI",
      field: "sni",
      size: "10%",
    },
    {
      title: "Address Mode",
      field: "addressMode",
      size: "10%",
    },
    {
      title: "",
      field: "",
      size: "3%",
      type: "delete",
    },
    {
      title: "",
      field: "",
      size: "3%",
      type: "show",
    },
    {
      field: "addresses",
      hide: true,
    },
    {
      field: "mode",
      hide: true,
    },
    {
      field: "id",
      hide: true,
    },
  ]

  const readableMode = [
    { field: "Round-Robin", value: 0 },
    { field: "Primary/Secondary", value: 1 },
  ]

  const addBrokenObject = (alarms, json) => {
    if (isNonEmptyArray(alarms)) {
      json.broken = {
        isBroken: true,
        domain: alarms[0].additionalInformation.domain,
        addresses: alarms.map((a) => a.additionalInformation.address),
        coloredFieldNames: Object.keys(json)
          .map((k) => {
            if (alarms.map((a) => a.additionalInformation.address).includes(json[k])) return k
          })
          .filter((a) => a),
        tooltip: alarms[0].probableCause,
      }
    } else {
      json.broken = {
        isBroken: false,
        domain: null,
        addresses: [],
        coloredFieldNames: [],
        tooltip: null,
      }
    }
    return json
  }

  const normalizeRows = (header, jsonArray, alarmTable) => {
    header.forEach((h) => {
      jsonArray.forEach((json) => {
        if (h.field !== "" && !json.hasOwnProperty(h.field)) json[h.field] = ""
        if (h.field === "addressMode") {
          json[h.field] = readableMode[parseInt(json.mode)].field
        } //qui non devo guardare il dominio ma gli indrizzi
        if (alarmTable.length > 0 && h.field.startsWith("address")) {
          const alarms = alarmTable.filter((alarm) => json.addresses.includes(alarm.additionalInformation.address))
          json = addBrokenObject(alarms, json)
        }
      })
    })
    return jsonArray
  }

  const makeDomainsDeleteable = (allRoutes, _domains) => {
    //we don't have routes but only domains, so domains can be deleteable

    if (!isNonEmptyArray(allRoutes) && isNonEmptyArray(_domains)) {
      return _domains.map((domain) => {
        domain.deleteable = true
        return domain
      })
      //we have both routes and domains, so we make deleteable only the ones that are not route also
    } else if (isNonEmptyArray(_domains) && isNonEmptyArray(allRoutes)) {
      return _domains.map((domain) => {
        if (allRoutes.find((route) => route.domain_name === domain.domain_name)) domain.deleteable = false
        else domain.deleteable = true
        return domain
      })
    } else return []
  }

  const checkForBrokenRoutes = (_domains, _routes, alarmTable) => {
    console.log(alarmTable, _domains, _routes)
    return _routes.map((r) => {
      _domains.forEach((d) => {
        if (r.domain_name === d.domain_name && d.broken.isBroken) {
          r.broken = {
            isBroken: true,
            domain: d.broken.domain,
            coloredFieldNames: ["domain_name"],
            tooltip: "There is an issue here! Check the Domain tab!",
          }
        }
      })
      return r
    })
  }

  const buildDomainsAndRoutes = async () => {
    let alarmTable = await fetchAlarmTable()
    alarmTable = buildAlarmTable(alarmTable.alarms)
    alarmTable = filterAlarmTable(alarmTable)

    let _domains = await fetchDomains()
    _domains = isNonEmptyArray(_domains.domains) ? _domains.domains : []

    let allRoutes = await fetchRoutes()
    const inbound = isNonEmptyArray(allRoutes.routes) ? allRoutes.routes : []
    const outbound = isNonEmptyArray(allRoutes.whitelist) ? allRoutes.whitelist : []
    allRoutes = [...inbound, ...outbound]

    allRoutes = normalizeRows(routeHeader, allRoutes, alarmTable)
    allRoutes.forEach((r) => (r.deleteable = true))
    _domains = normalizeRows(domainHeader, _domains, alarmTable)
    _domains = makeDomainsDeleteable(allRoutes, _domains)
    if (alarmTable.length > 0) allRoutes = checkForBrokenRoutes(_domains, allRoutes, alarmTable)

    setdomains(_domains)
    setinRoutes(inbound)
    setwhitelist(outbound)
    setroutesToShow(allRoutes)
  }

  const buildAlarmTable = (fetchedAlarmTable) => {
    const _alarmTable = new Set()
    //getting the mib object
    Object.entries(fetchedAlarmTable).map((k, v) => {
      const mib = k[1]
      //extracting the mib id and the alarm object
      return Object.entries(mib).map((k1, v1) => {
        const alarmObject = k1[1]
        //build the alarm object by checking the mib id on the mibTable (in the config file)
        return alarmObject.map((alarm) => {
          if (alarm.model) {
            alarm.model.involvedCounterpart = alarm.model.additionalInformation.counterPart

            // alarm.model.notificationType!=='notifyClearedAlarm' && _alarmTable.add(alarm.model)
            _alarmTable.add(alarm.model)
          }
          return alarm
        })
      })
    })

    return Array.from(_alarmTable)
  }

  const filterAlarmTable = (alarms) => {
    const probableCauseFilter = ["PEER_IP_ADDRESS_COMMUNICATION_FAILURE", "HANDSHAKE_KO", "HANDSHAKE_TIMEOUT"]
    const filteredAlarms = alarms.filter((el) => probableCauseFilter.includes(el.probableCause))
    return filteredAlarms
  }

  useEffect(() => {
    if (!isNonEmptyArray(inRoutes) || !isNonEmptyArray(domains) || !isNonEmptyArray(whitelist)) {
      buildDomainsAndRoutes()
    }
  }, [])

  //if data have been fetched, we set loading to false
  //When one of the following has no data, it just has an empty array (not null)
  useEffect(() => {
    if (inRoutes != null && domains != null && whitelist != null) setloading(false)
  }, [inRoutes, domains, whitelist])

  //Creating  Redis key, if necessary
  const createRedisKey = (key, data) => {
    httpApi.callPostAPI({ url: key, data, snack: { show: false } })
  }

  const needToCreateRedisKey = async () => {
    return Promise.all([
      httpApi.callGetAPI({
        url: "json/routing",
        snack: { show: false },
        errorCallback: (err) => {
          err.response.status === 404 && createRedisKey("json/routing", [])
          return "routing"
        },
        successCallback: (data) => 1,
      }),
      httpApi.callGetAPI({
        url: "json/whitelist",
        snack: { show: false },
        errorCallback: (err) => {
          err.response.status === 404 && createRedisKey("json/whitelist", [])
          return "whitelist"
        },
        successCallback: (data) => 1,
      }),
      httpApi.callGetAPI({
        url: "json/domaintable",
        snack: { show: false },
        errorCallback: (err) => {
          err.response.status === 404 && createRedisKey("json/domaintable", [])
          return "domaintable"
        },
        successCallback: (data) => 1,
      }),
    ])
  }

  useEffect(() => {
    needToCreateRedisKey().then((data) => {
      if (data.filter((el) => el !== 1).length > 0)
        snackbar.setSnackbarInfo({
          status: "warning",
          message: `The following requested <b>Redis keys</b> are missing and will be created: <i>${data.join(", ")}</i>`,
        })
    })
  }, [])

  const fetchAlarmTable = async () => {
    let alarms = []

    alarms = await httpApi.callGetAPI({
      snack: { show: false },
      url: `json/${config.configInfo.protectionGroupID}alarm-table`,
      errorCallback: (err) => [],
    })
    return { alarms }
  }

  const fetchRoutes = async () => {
    let routes = []
    let whitelist = []

    routes = await httpApi.callGetAPI({
      snack: { show: false },
      url: "json/routing",
    })
    whitelist = await httpApi.callGetAPI({
      url: "json/whitelist",
      snack: { show: false },
    })

    return { routes, whitelist }
  }

  const fetchDomains = async () => {
    let domains = []

    domains = await httpApi
      .callGetAPI({
        url: "json/domaintable",
        snack: { show: false },
      })
      .then((data) => data)

    return {
      domains,
    }
  }

  const routeModalShow = () => {
    setrouteModal(true)
  }

  const routeModalHide = () => {
    setrouteModal(false)
  }
  const domainModalShow = () => {
    setdomainModal(true)
  }

  const domainModalHide = () => {
    setdomainModal(false)
  }

  const addInOutRoutes = async (_routes, dialogueMode) => {
    await httpApi.callPostAPI({
      url: dialogueMode === "inbound" ? `json/routing` : `json/whitelist`,
      snack: { show: true },
      data: _routes,
      successCallback: async () => {
        buildDomainsAndRoutes()
      },
    })
  }

  const addRoute = async (route) => {
    let _inRoutes = [...inRoutes]
    let _whitelist = [...whitelist]
    if (route.dialogue_mode === "inbound") {
      _inRoutes = [..._inRoutes, route]
      addInOutRoutes(_inRoutes, "inbound")
    }
    if (route.dialogue_mode === "outbound") {
      _whitelist = [..._whitelist, route]
      addInOutRoutes(_whitelist, "outbound")
    }
  }

  const deleteRoute = async (route) => {
    let _routes = []
    if (route.dialogue_mode === "inbound") {
      _routes = inRoutes.filter((r) => r.id !== route.id)
    } else if (route.dialogue_mode === "outbound") {
      _routes = whitelist.filter((r) => r.id !== route.id)
    }

    await httpApi.callPostAPI({
      url: `${route.dialogue_mode === "inbound" ? "json/routing" : "json/whitelist"}`,
      data: _routes,
      snack: {
        show: true,
        success: { status: "success", message: "Route correctly deleted" },
      },
      successCallback: async () => {
        buildDomainsAndRoutes()
      },
    })
  }

  const addDomain = async (domain) => {
    const tmpDomains = [...domains, domain]
    tmpDomains.forEach((d) => {
      if (d.hasOwnProperty("addressMode")) delete d.addressMode
      if (d.hasOwnProperty("deleteable")) delete d.deleteable
    })

    await httpApi.callPostAPI({
      url: `json/domaintable`,
      data: tmpDomains,
      snack: {
        show: true,
        success: { status: "success", message: "Route domain correctly added" },
      },
      successCallback: async () => {
        buildDomainsAndRoutes()
      },
    })
  }

  const deleteDomain = async (domain) => {
    const tmpDomains = domains.filter((r) => r.id !== domain.id)
    await httpApi.callPostAPI({
      url: `json/domaintable`,
      data: tmpDomains,
      snack: {
        show: true,
        success: {
          status: "success",
          message: "Route domain correctly deleted",
        },
      },
      successCallback: async () => {
        buildDomainsAndRoutes()
      },
    })
  }

  const sendRedis = async () => {
    const _domains = await httpApi.callGetAPI({
      url: "json/domaintable",
      snack: { show: false },
    })
    const _inRoutes = await httpApi.callGetAPI({
      url: "json/routing",
      snack: { show: false },
    })
    const _whitelist = await httpApi.callGetAPI({
      url: "json/whitelist",
      snack: { show: false },
    })

    let inRouteTable = _domains
      .filter((d) =>
        _inRoutes.find((r) => {
          if (r.domain_name === d.domain_name) {
            d.direction = r.direction
            return d
          }
        })
      )
      .map((rt) => {
        if (rt.direction === "Hub") {
          return {
            [rt.dns]: [_domains.find((d) => d.is_hub === true).address_1],
          }
        } else
          return {
            [rt.dns]: rt.addresses.filter((r) => r !== ""),
          }
      })

    let outRouteTable = _domains
      .filter((d) =>
        _whitelist.find((r) => {
          if (r.domain_name === d.domain_name) {
            d.direction = r.direction
            return d
          }
        })
      )
      .map((rt) => {
        if (rt.direction === "Hub") {
          return {
            [rt.dns]: [_domains.find((d) => d.is_hub === true).address_1],
          }
        } else
          return {
            [rt.dns]: rt.addresses.filter((r) => r !== ""),
          }
      })

    let filteredDomains = _domains
      .filter((d) =>
        [..._inRoutes, ..._whitelist].find((r) => {
          if (r.domain_name === d.domain_name) {
            d.isFqdn = r.mfw.includes("TelescopicFQDN")
            return d
          }
        })
      )
      .map((fd) => {
        return { [fd.dns]: fd.isFqdn }
      })

    let sni = _domains.map((d) => ({
      [d.dns]: d.sni ? `${d.sni}.${d.dns}` : d.dns,
    }))

    //From an array of objects to an object of key-value pairs
    inRouteTable = Object.assign({}, ...inRouteTable)
    outRouteTable = Object.assign({}, ...outRouteTable)
    outRouteTable = Object.keys(outRouteTable).map((k) => k)
    filteredDomains = Object.assign({}, ...filteredDomains)
    sni = Object.assign({}, ...sni)

    callSendRedisApi({
      sni,
      inRouteTable,
      outRouteTable,
      filteredDomains,
    }).then((data) => {
      if (data.filter((el) => el !== 1).length > 0) {
        snackbar.setSnackbarInfo({
          status: "error",
          message: `There was an error on saving on the following <b>Redis keys</b>: <i>${data.join(", ")}</i>`,
        })
      } else {
        snackbar.setSnackbarInfo({
          status: "success",
          message: `Data successfully saved!`,
        })
      }
    })
  }

  const callSendRedisApi = async (obj) => {
    return Promise.all([
      // the route table API must be called after the snihostname
      httpApi.callPostAPI({
        url: `json/${config.configInfo.protectionGroupID}snihostname`,
        data: obj.sni,
        successCallback: () => {
          return httpApi.callPostAPI({
            url: `json/${config.configInfo.protectionGroupID}route-table`,
            data: obj.inRouteTable,
            successCallback: () => 1,
            errorCallback: () => `${config.configInfo.protectionGroupID}route-table`,
          })
        },
        errorCallback: () => `${config.configInfo.protectionGroupID}snihostname`,
      }),

      httpApi.callPostAPI({
        url: `json/${config.configInfo.protectionGroupID}domain_whitelist`,
        data: obj.outRouteTable,
        successCallback: () => 1,
        errorCallback: () => `${config.configInfo.protectionGroupID}domain_whitelist`,
      }),

      httpApi.callPostAPI({
        url: `json/${config.configInfo.protectionGroupID}fqdn-table`,
        data: obj.filteredDomains,
        successCallback: () => 1,
        errorCallback: () => `${config.configInfo.protectionGroupID}fqdn-table`,
      }),
    ])
  }

  const importFile = () => {
    inputRef.current.click()
  }

  const handleOnChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setfileToBeUploaded(e.target.files[0])
      e.target.value = ""
    }
  }

  const uploadFile = async (url, formData, type) => {
    return httpApi.callUploadAPI({
      url,
      formData,
      snack: {
        success: {
          status: "success",
          message: `${type} Table successfully uploaded`,
        },
      },
    })
  }

  const checkImportedData = (data, type, importedDomains = null) => {
    if (type === "routing") return checkRoutingImportedData(data, importedDomains)
    if (type === "domain") return checkDomainImportedData(data, seppDomain, namespace)
    return []
  }

  const checkPreliminaryImportErrors = (data) => {
    try {
      data = JSON.parse(data)
    } catch (err) {
      console.log(err)
      setuploadErrors(["The file you provided is not in a JSON format"])
      return -1
    }
    if (!data.hasOwnProperty("domains") || !data.hasOwnProperty("routes")) {
      setuploadErrors(['Your file must contain either the "domains" and "routes" section'])
      return -1
    }
    return data
  }

  const onReaderLoad = async (e, tab) => {
    let data = e.target.result
    data = checkPreliminaryImportErrors(data)
    if (data === -1) return

    const _domains = data.domains || []
    const _routes = data.routes || []
    let { verifiedDomains, domainsErrors } = checkImportedData(_domains, "domain")
    let { verifiedRoutes, routesErrors } = checkImportedData(_routes, "routing", verifiedDomains)

    domainsErrors = Array.from(domainsErrors)
    routesErrors = Array.from(routesErrors)
    const errors = [...domainsErrors, ...routesErrors]

    if (errors.length > 0) {
      setuploadErrors(errors)
      return
    } else setuploadErrors([])

    const inFormData = new FormData()
    const outFormData = new FormData()
    const domainsFormData = new FormData()
    const _inRoutes = verifiedRoutes.filter((route) => route.dialogue_mode === "inbound")
    const _whitelist = verifiedRoutes.filter((route) => route.dialogue_mode === "outbound")
    inFormData.append("file", new Blob([JSON.stringify(_inRoutes)]))
    outFormData.append("file", new Blob([JSON.stringify(_whitelist)]))
    domainsFormData.append("file", new Blob([JSON.stringify(verifiedDomains)]))

    const res = await Promise.all([
      uploadFile(`file/routing`, inFormData, "Routing"),
      uploadFile(`file/whitelist`, outFormData, "Whitelist"),
      uploadFile(`file/domaintable`, domainsFormData, "Domain"),
    ])
    if (!res.find((r) => r.status !== 200)) {
      buildDomainsAndRoutes()
    }
  }

  const uploadTable = (tab) => {
    const reader = new FileReader()
    reader.onload = (e) => onReaderLoad(e, tab)
    reader.readAsText(fileToBeUploaded)
  }

  const performExport = (data) => {
    if (data) {
      const fileName = "routing-domain-table"
      const json = JSON.stringify(data, null, 2)
      const blob = new Blob([json], { type: "application/json" })
      const href = URL.createObjectURL(blob)

      const link = document.createElement("a")
      link.href = href
      link.download = fileName + ".json"
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      URL.revokeObjectURL(href)

      snackbar.setSnackbarInfo({
        status: "success",
        message: `Table successfully exported`,
      })
    } else {
      snackbar.setSnackbarInfo({
        status: "error",
        message: "Something went wrong",
      })
    }
  }

  const exportTemplate = () => {
    const dataToExport = {
      domains: [
        {
          domain_name: `mandatory`,
          dns: `mandatory`,
          is_hub: `optional (must be boolean true or false)`,
          address_1: `mandatory (must start with 'http://' and ends with '/')`,
          address_2: `optional (must start with 'http://' and ends with '/')`,
          address_3: `optional (must start with 'http://' and ends with '/')`,
          address_4: `optional (must start with 'http://' and ends with '/')`,
          mode: `optional (must be integer 0 or 1)`,
          sni: `mandatory`,
        },
      ],
      routes: [
        {
          domain_name: `mandatory (must be on of the 'domain_name' of the 'domains' section)`,
          direction: `mandatory (allowed values are 'inbound' or 'outbound')`,
          mfw: `leave this empty if you selected 'outbound' for the 'direction' field. Optional (allowed values are 'ApiRoot' and 'TelescopicFQDN')`,
          dialogue_mode: `leave this empty if you selected 'outbound' for the 'direction' field. Optional (unique value allowed is 'Direct')`,
        },
      ],
    }

    performExport(dataToExport)
  }

  const exportFile = async () => {
    const result = await Promise.all([
      httpApi.callGetAPI({ url: "json/routing" }).then((data) => data),
      httpApi.callGetAPI({ url: "json/whitelist" }).then((data) => data),
      httpApi.callGetAPI({ url: "json/domaintable" }).then((data) => data),
    ])
    result[0] = removeAllButFields(["domain_name", "direction", "dialogue_mode", "mfw"], result[0])
    result[1] = removeAllButFields(["domain_name", "direction", "dialogue_mode", "mfw"], result[1])
    result[2] = removeAllButFields(
      ["address_1", "address_2", "address_3", "address_4", "dns", "domain_name", "is_hub", "mode", "sni"],
      result[2]
    )
    const dataToExport = {
      domains: result[2],
      routes: [...result[0], ...result[1]],
    }
    performExport(dataToExport)
  }

  const checkForAssociatedRoutes = () => {
    return (
      domains.filter(
        (d) => routesToShow.find((r) => r.domain_name === d.domain_name) || whitelist.find((w) => w.domain_name === d.domain_name)
      ).length > 0
    )
  }

  return (
    <div className="page">
      <Spinner show={loading} />
      <Modal show={openImportModal} onHide={() => setopenImportModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Importing file...</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul>
            <li>
              If you want to import a <b>new routes and domains from scratch</b> overwriting the current routing table, click on{" "}
              <b>"New"</b> for downloading a skeleton file to be filled.
            </li>
            <li>
              If you want to <b>update the current routing table</b> (routes and domains), click on <b>"Add"</b> for downloading the file to
              be updated.
            </li>
            <li>
              Click <b>"Import"</b> to upload the file once it is ready.
            </li>
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setopenImportModal(false)}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              exportFile()
              setopenImportModal(false)
            }}
          >
            Add
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              exportTemplate()
              setopenImportModal(false)
            }}
          >
            New
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              importFile()
              setopenImportModal(false)
            }}
          >
            Import
          </Button>
        </Modal.Footer>
      </Modal>
      <ConfirmModal
        bsSize="medium"
        show={openConfirmModal}
        onHide={() => setopenConfirmModal(false)}
        onClose={() => setopenConfirmModal(false)}
        title="Upload "
        className="text-center"
        onAccept={(e) => {
          // setuploadErrors([])
          setopenConfirmModal(false)
          uploadTable(tab)
        }}
        closeText="Close"
      >
        <div>
          <p>This action will permanently replace the current redis key with the one you are providing in the file!</p>
          <p>Do you want to continue?</p>
        </div>
      </ConfirmModal>
      {!loading && (
        <>
          <Nav defaultActiveKey="routing" className="tabRouting" fill variant="tabs">
            <Nav.Item onClick={() => settab("routing")}>
              <Nav.Link eventKey="routing">ROUTING TABLE</Nav.Link>
            </Nav.Item>
            <Nav.Item
              onClick={() => {
                //if we deleted some routes, when we switch to domain tab we need to check if some domains could be now deleatable
                buildDomainsAndRoutes()
                settab("domain")
              }}
            >
              <Nav.Link eventKey="domain">REMOTE DOMAIN TABLE</Nav.Link>
            </Nav.Item>
          </Nav>
          {tab === "routing" && (
            <>
              <AddRouteModal
                show={routeModal}
                onHide={routeModalHide}
                addRoute={addRoute}
                domains={domains}
                routes={inRoutes}
                whitelist={whitelist}
              />
              <div className="routing-panel">
                {/* <RoutesList routes={routesToShow || []} deleteRoute={deleteRoute} /> */}
                {routesToShow.length > 0 ? (
                  <ListTable
                    elements={routesToShow}
                    header={routeHeader}
                    sortable
                    searchable
                    collapsible
                    dense
                    cellActions={[
                      {
                        elementName: "route",
                        type: "delete",
                        method: deleteRoute,
                        size: routeHeader.find((h) => h.hasOwnProperty("type") && h.type === "delete")?.size,
                      },
                      {
                        elementName: "route",
                        type: "show",
                        size: routeHeader.find((h) => h.hasOwnProperty("type") && h.type === "show")?.size,
                      },
                    ]}
                  ></ListTable>
                ) : (
                  <h3 className="no-table-data">No data</h3>
                )}

                <OverlayTrigger placement="top" overlay={<Tooltip style={{ position: "absolute", zIndex: "100001" }}>Add Route</Tooltip>}>
                  <div
                    style={{
                      ...(!isNonEmptyArray(domains) ? { opacity: "0.3" } : { opacity: "1" }),
                    }}
                    onClick={() => isNonEmptyArray(domains) && routeModalShow()}
                    className="addBtn action routingBtnPosition first"
                  >
                    <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
                  </div>
                </OverlayTrigger>

                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip style={{ position: "absolute", zIndex: "100001" }}>Send To Redis</Tooltip>}
                >
                  <div onClick={() => sendRedis()} className="addBtn send action routingBtnPosition second">
                    <FontAwesomeIcon icon={faSync}></FontAwesomeIcon>
                  </div>
                </OverlayTrigger>

                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip style={{ position: "absolute", zIndex: "100001" }}>Import domains and routes from JSON file</Tooltip>}
                >
                  <div onClick={() => setopenImportModal(true)} className="addBtn import action routingBtnPosition third">
                    <input
                      ref={inputRef}
                      type="file"
                      accept="application/JSON"
                      id="jsonUpload"
                      style={{ display: "none" }}
                      onChange={(e) => {
                        handleOnChange(e)
                        setopenConfirmModal(true)
                      }}
                    />
                    <FontAwesomeIcon icon={faFileImport}></FontAwesomeIcon>
                  </div>
                </OverlayTrigger>

                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip style={{ position: "absolute", zIndex: "100001" }}>
                      {isNonEmptyArray(domains) ? `Export domains and routes to JSON file` : `Export domains and routes empty template`}
                    </Tooltip>
                  }
                >
                  <div
                    onClick={() => (isNonEmptyArray(domains) ? exportFile() : exportTemplate())}
                    className="addBtn export action routingBtnPosition fourth"
                  >
                    <FontAwesomeIcon icon={faFileExport}></FontAwesomeIcon>
                  </div>
                </OverlayTrigger>
                {uploadErrors.length > 0 && (
                  <div className="upload-errors">
                    <span className="errors-title">Some errors have been found on your file!</span>
                    {uploadErrors.map((err) => (
                      <div>- {err}.</div>
                    ))}
                  </div>
                )}
              </div>
            </>
          )}
          {tab === "domain" && (
            <>
              <AddDomainModal domains={domains} show={domainModal} onHide={domainModalHide} addDomain={addDomain} />
              <div className="domain-panel">
                {/* <DomainList domains={domains || []} routes={routesToShow || []} deleteDomain={deleteDomain} /> */}
                {domains.length > 0 ? (
                  <ListTable
                    elements={domains}
                    header={domainHeader}
                    sortable
                    searchable
                    collapsible
                    dense
                    cellActions={[
                      {
                        elementName: "domain",
                        type: "delete",
                        method: deleteDomain,
                        size: domainHeader.find((h) => h.hasOwnProperty("type") && h.type === "delete")?.size,
                      },
                      {
                        elementName: "domain",
                        type: "show",
                        size: domainHeader.find((h) => h.hasOwnProperty("type") && h.type === "show")?.size,
                      },
                    ]}
                  ></ListTable>
                ) : (
                  <h3 className="no-table-data">No data</h3>
                )}
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip style={{ position: "absolute", zIndex: "100001" }}>Add Remote Domain</Tooltip>}
                >
                  <div onClick={() => domainModalShow()} className="addBtn action routingBtnPosition first">
                    <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
                  </div>
                </OverlayTrigger>

                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip style={{ position: "absolute", zIndex: "100001" }}>Send To Redis</Tooltip>}
                >
                  <div onClick={() => sendRedis()} className="addBtn send action routingBtnPosition second">
                    <FontAwesomeIcon icon={faSync}></FontAwesomeIcon>
                  </div>
                </OverlayTrigger>

                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip style={{ position: "absolute", zIndex: "100001" }}>Import domains and routes from JSON file</Tooltip>}
                >
                  <div onClick={() => setopenImportModal(true)} className="addBtn import action routingBtnPosition third">
                    <input
                      ref={inputRef}
                      type="file"
                      accept="application/JSON"
                      id="jsonUpload"
                      style={{ display: "none" }}
                      onChange={(e) => {
                        handleOnChange(e)
                        setopenConfirmModal(true)
                      }}
                    />
                    <FontAwesomeIcon icon={faFileImport}></FontAwesomeIcon>
                  </div>
                </OverlayTrigger>

                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip style={{ position: "absolute", zIndex: "100001" }}>
                      {isNonEmptyArray(domains) ? `Export domains and routes to JSON file` : `Export domains and routes empty template`}
                    </Tooltip>
                  }
                >
                  <div
                    onClick={() => (isNonEmptyArray(domains) ? exportFile() : exportTemplate())}
                    className="addBtn export action routingBtnPosition fourth"
                  >
                    <FontAwesomeIcon icon={faFileExport}></FontAwesomeIcon>
                  </div>
                </OverlayTrigger>
                {uploadErrors.length > 0 && (
                  <div className="upload-errors">
                    <span className="errors-title">Some errors have been found on your file!</span>
                    {uploadErrors.map((err) => (
                      <div>- {err}.</div>
                    ))}
                  </div>
                )}
              </div>
            </>
          )}
        </>
      )}
    </div>
  )
}

export { RoutingTable as Routing }
