import axios from "axios"
import React, { createContext, useContext, useState, useEffect } from "react"

const Login = createContext()

const useLogin = () => {
  return useContext(Login)
}

const LoginProvider = ({ children }) => {
  const [isUserLogged, setisUserLogged] = useState(!!localStorage.getItem("Token"))
  const [notValidTokenMessage, setnotValidTokenMessage] = useState(null)

  // Effetto per sincronizzare lo stato con il localStorage
  useEffect(() => {
    const token = localStorage.getItem("Token")
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`
      setisUserLogged(true) // Imposta l'utente come loggato se esiste un token
    }
  }, [])

  return (
    <Login.Provider
      value={{
        isUserLogged,
        setisUserLogged,
        notValidTokenMessage,
        setnotValidTokenMessage,
      }}
    >
      {children}
    </Login.Provider>
  )
}

export { LoginProvider, useLogin }
