import React, { useState, useEffect } from "react"
import { NavMenu } from "./NavMenu"
// import Header from "./UI/Header/Header"
import { useSnackbar } from "../contexts/snackbarContext"
import { faThumbsUp } from "@fortawesome/free-solid-svg-icons"
import toast, { Toaster } from "react-hot-toast"
import { useConfig } from "../contexts/configContext"
import parse from "html-react-parser"
import Fade from "react-bootstrap/Fade"

import axios from "axios"
import useHttpApi from "./useHttpApi"
import { useLogin } from "../contexts/loginContext"
import { useNavigate } from "react-router-dom"
import { customizeToast, isNonEmptyObject } from "../utils"

const Layout = (props) => {
  const [protectionGroup, setprotectionGroup] = useState(null)
  const snackbar = useSnackbar()
  const config = useConfig()
  const httpApi = useHttpApi()
  const login = useLogin()
  const navigate = useNavigate()

  const fadeInStyle = {
    borderRadius: "10px",
    padding: "8px",
    boxShadow: "10px 10px 8px black",
    maxWidth: "40%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    border: "4px solid transparent",
  }

  useEffect(() => {
    const token = localStorage.getItem("Token")
    if (!token) {
      login.setisUserLogged(false)
      navigate("/login")
    } else if (!login.isUserLogged) navigate("/login")
  }, [])

  useEffect(() => {
    httpApi.isReady.current &&
      httpApi.callGetAPI({
        url: "json/snsm_pg_config",
        snack: { show: false },
        successCallback: (data) => {
          if (data.snsm_pg_enabled === "ENABLED") {
            setprotectionGroup({
              name: data.snsm_pg_name,
              members: data.snsm_pg_members || [],
            })
          }
        },
      })
  }, [httpApi.isReady.current])

  useEffect(() => {
    if (snackbar && snackbar.snackbarInfo && snackbar.snackbarInfo.status !== "") {
      customizeToast(snackbar, toast)
    }
  }, [snackbar])

  return (
    <>
      <Toaster gutter={24} />
      {/* {!config.loading && <Header />} */}
      <NavMenu capabilities={props.capabilities} />

      <div
        className="rowDiv j-between"
        style={{
          width: "100%",
          backgroundColor: "#1e2b2d",
          paddingTop: "20px",
        }}
      >
        <div style={{ marginLeft: "14.7%", color: "white", fontSize: "26pt" }}>{props.title}</div>
        <div style={{ padding: "10px 15px 30px 15px" }}>
          <img src="/Logo.png" width="174px" height="36px"></img>
        </div>
      </div>
      <div className="sepp-main-info">
        <div className="rowDiv j-between" style={{ width: "98%", marginLeft: "1%" }}>
          <div className="colDiv j-center">
            <div
              className="with-breadcrumb"
              style={{
                color: config.namespace ? "white" : "orange",
                width: "100%",
                marginLeft: "0%",
              }}
            >
              SEPP ID: {config.namespace || "NOT SET!"}
            </div>
            <div
              className="with-breadcrumb"
              style={{
                color: config?.configInfo?.tenant ? "white" : "orange",
                width: "100%",
                marginLeft: "0%",
              }}
            >
              Tenant: {config?.configInfo?.tenant || "NOT SET!"}
            </div>
            <div
              className="with-breadcrumb"
              style={{
                color: config?.configInfo?.seppCommonName ? "white" : "orange",
                width: "100%",
                marginLeft: "0%",
                textTransform: "unset",
              }}
            >
              SNI: {config?.configInfo?.seppCommonName || "NOT SET!"}
            </div>
          </div>
          <div className="colDiv j-center">
            <div
              className="with-breadcrumb"
              style={{
                color: protectionGroup?.name ? "white" : "orange",
                width: "100%",
                marginLeft: "0%",
              }}
            >
              PROTECTION GROUP: {protectionGroup?.name || "NOT SET!"}
            </div>
            {protectionGroup?.members && protectionGroup?.members.length > 0 && (
              <div
                className="with-breadcrumb"
                style={{
                  color: protectionGroup?.members ? "white" : "orange",
                  width: "100%",
                  marginLeft: "0%",
                }}
              >
                MEMBERS: {protectionGroup.members?.join(", ")}
              </div>
            )}
          </div>
        </div>
      </div>
      {isNonEmptyObject(config?.configInfo) && (
        <div className="App" style={{ backgroundColor: "#1e2b2d", width: "86%" }}>
          {props.children}
        </div>
      )}
    </>
  )
}

export default Layout
