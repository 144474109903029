import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

const ConfirmModal = (props) => {
  return (
    <Modal {...props}>
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.children}</Modal.Body>
      <Modal.Footer>
        <Button bsStyle='default confirmButton' onClick={props.onClose}>
          {props.closeText || 'Confirm'}
        </Button>
        {props.onAccept && (
          <Button bsStyle='default confirmButton' onClick={props.onAccept}>
            Confirm
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  )
}

export default ConfirmModal
