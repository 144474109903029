import { useEffect, useRef, useState } from "react"
import axios from "axios"
import { useSnackbar } from "../contexts/snackbarContext"
import { useLogin } from "../contexts/loginContext"
import { useNavigate } from "react-router-dom"
import { useConfig } from "../contexts/configContext"
import { isNonEmptyObject } from "../utils"

const useHttpApi = (props) => {
  const isReady = useRef(false)
  const snackbar = useSnackbar()
  const login = useLogin()
  const navigate = useNavigate()
  const config = useConfig()

  useEffect(() => {
    if (localStorage.getItem("Token") && isNonEmptyObject(config?.configInfo)) {
      axios.defaults.baseURL = config.configInfo.apiUrl
      axios.interceptors.request.use(
        (config) => {
          const token = localStorage.getItem("Token")
          if (token) {
            config.headers["Authorization"] = `Bearer ${token}`
          }
          return config
        },
        (error) => {
          return Promise.reject(error)
        }
      )
      isReady.current = true
    }
  }, [config, localStorage])

  const defaultSuccessMessage = {
    status: "success",
    message: "The operation has been completed successfully!",
  }

  const err404 = {
    status: "error",
    message: "The requested Redis key does not exist.",
  }

  const checkForTokenErrors = (err) => {
    if (err.response.status === 403) {
      login.setisUserLogged(false)
      login.setnotValidTokenMessage(`Your Token has expired or is not valid. Please sign in again with a valid token.`)
      localStorage.removeItem("Token")
      navigate("/login")
      return -1
    }
    if (err.response.status === 401) {
      login.setisUserLogged(false)
      login.setnotValidTokenMessage(`You need a valid token in order to proceed. Please sign in with a valid token`)
      localStorage.removeItem("Token")
      navigate("/login")
      return -1
    }
  }

  const callPostAPI = async (postObj) => {
    return await axios
      .post(postObj.url, postObj.data)
      .then((res) => {
        console.log(postObj)
        if (postObj.snack?.show) {
          if (postObj.snack?.success) {
            snackbar.setSnackbarInfo(postObj.snack.success)
          } else {
            snackbar.setSnackbarInfo(defaultSuccessMessage)
          }
        }

        if (postObj.successCallback) {
          return postObj.successCallback(res)
        }
        return res
      })
      .catch((err) => {
        console.error(err)
        if (checkForTokenErrors(err) === -1) return
        if (postObj.snack?.error) {
          snackbar.setSnackbarInfo(postObj.snack.error)
        } else if (postObj.snack?.warning) {
          snackbar.setSnackbarInfo(postObj.snack.warning)
        } else if (postObj.snack?.info) {
          snackbar.setSnackbarInfo(postObj.snack.info)
        } else if (err.response.status === 404) {
          snackbar.setSnackbarInfo(err404)
        } else {
          snackbar.setSnackbarInfo({
            status: "error",
            message: `<b>An error occurred:</b> ${err.message}`,
          })
        }

        if (postObj.errorCallback) {
          return postObj.errorCallback(err)
        }
      })
  }

  const callGetAPI = async (getObj) => {
    return await axios
      .get(getObj.url)
      .then((res) => {
        if (getObj.successCallback) {
          return getObj.successCallback(res.data)
        }
        return res.data
      })
      .catch((err) => {
        console.error(err)
        if (checkForTokenErrors(err) === -1) return
        if (getObj.snack?.show) {
          if (getObj.snack?.error) {
            snackbar.setSnackbarInfo(getObj.snack.error)
          } else if (getObj.snack?.warning) {
            snackbar.setSnackbarInfo(getObj.snack.warning)
          } else if (getObj.snack?.info) {
            snackbar.setSnackbarInfo(getObj.snack.info)
          } else if (err.response.status === 404) {
            snackbar.setSnackbarInfo(err404)
          } else {
            snackbar.setSnackbarInfo({
              status: "error",
              message: `<b>An error occurred:</b> ${err.message}`,
            })
          }
        }

        if (getObj.errorCallback) {
          return getObj.errorCallback(err)
        }
      })
  }

  const callDeleteAPI = async (deleteObj) => {
    return await axios({
      url: deleteObj.url,
      method: "delete",
    })
      .then((res) => {
        if (deleteObj.snack?.success) {
          snackbar.setSnackbarInfo(deleteObj.snack.success)
        } else {
          snackbar.setSnackbarInfo(defaultSuccessMessage)
        }
        if (deleteObj.successCallback) {
          return deleteObj.successCallback(res)
        }
        return res
      })
      .catch((err) => {
        console.error(err)
        if (checkForTokenErrors(err) === -1) return
        if (deleteObj.snack?.error) {
          snackbar.setSnackbarInfo(deleteObj.snack.error)
        } else if (deleteObj.snack?.warning) {
          snackbar.setSnackbarInfo(deleteObj.snack.warning)
        } else if (deleteObj.snack?.info) {
          snackbar.setSnackbarInfo(deleteObj.snack.info)
        } else if (err.response.status === 404) {
          snackbar.setSnackbarInfo(err404)
        } else {
          snackbar.setSnackbarInfo({
            status: "error",
            message: `<b>An error occurred:</b> ${err.message}`,
          })
        }

        if (deleteObj.errorCallback) {
          return deleteObj.errorCallback(err)
        }
      })
  }

  const callUploadAPI = async (uploadObj) => {
    return await axios({
      url: uploadObj.url,
      data: uploadObj.formData,
      method: "post",
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        if (uploadObj.snack?.success) {
          snackbar.setSnackbarInfo(uploadObj.snack.success)
        } else {
          snackbar.setSnackbarInfo(defaultSuccessMessage)
        }
        if (uploadObj.successCallback) {
          return uploadObj.successCallback(res)
        }
        return res
      })
      .catch((err) => {
        console.error(err)
        if (checkForTokenErrors(err) === -1) return
        if (uploadObj.snack?.error) {
          snackbar.setSnackbarInfo(uploadObj.snack.error)
        } else if (uploadObj.snack?.warning) {
          snackbar.setSnackbarInfo(uploadObj.snack.warning)
        } else if (uploadObj.snack?.info) {
          snackbar.setSnackbarInfo(uploadObj.snack.info)
        } else if (err.response.status === 404) {
          snackbar.setSnackbarInfo(err404)
        } else {
          snackbar.setSnackbarInfo({
            status: "error",
            message: `<b>An error occurred:</b> ${err.message}`,
          })
        }

        if (uploadObj.errorCallback) {
          return uploadObj.errorCallback(err)
        }
      })
  }

  const completeDownload = (fileContent, downloadObj, file) => {
    const extension = file.type === "excel" ? ".xlsx" : ".json"
    let blob = null
    if (file.type === "json") {
      const json = JSON.stringify(fileContent, null, 2)
      blob = new Blob([json], { type: "application/json" })
    }
    if (file.type === "excel") {
      blob = new Blob([fileContent], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
    }

    const href = URL.createObjectURL(blob)
    const link = document.createElement("a")
    link.href = href
    link.download = file.name + extension
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    URL.revokeObjectURL(href)

    if (downloadObj.snack?.success) {
      snackbar.setSnackbarInfo(downloadObj.snack.success)
    } else {
      snackbar.setSnackbarInfo(defaultSuccessMessage)
    }
    if (downloadObj.successCallback) {
      return downloadObj.successCallback(fileContent)
    }
    return fileContent
  }

  const callDownloadAPI = async (downloadObj, file) => {
    return await axios({
      url: downloadObj.url,
      method: "get",
      ...(file.type === "excel" && { responseType: "blob" }),
    })
      .then(async (res) => {
        if (res.data) {
          return completeDownload(res.data, downloadObj, file)
        }
      })
      .catch((err) => {
        console.error(err)
        if (checkForTokenErrors(err) === -1) return
        if (downloadObj.snack?.error) {
          snackbar.setSnackbarInfo(downloadObj.snack.error)
        } else if (downloadObj.snack?.warning) {
          snackbar.setSnackbarInfo(downloadObj.snack.warning)
        } else if (downloadObj.snack?.info) {
          snackbar.setSnackbarInfo(downloadObj.snack.info)
        } else if (err.response.status === 404) {
          snackbar.setSnackbarInfo(err404)
        } else {
          snackbar.setSnackbarInfo({
            status: "error",
            message: `<b>An error occurred:</b> ${err.message}`,
          })
        }

        if (downloadObj.errorCallback) {
          return downloadObj.errorCallback(err)
        }
      })
  }

  return {
    callGetAPI,
    callPostAPI,
    callUploadAPI,
    callDownloadAPI,
    callDeleteAPI,
    completeDownload,
    isReady,
  }
}

export default useHttpApi
