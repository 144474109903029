import React, { useEffect } from "react"
import { Route, Routes, useNavigate } from "react-router"
import Layout from "./components/Layout"
import { Routing } from "./components/Routing/Routing"
import withErrorBoundary from "./services/withErrorBoundary"
import { SnackbarProvider } from "./contexts/snackbarContext"

import "./App.less"
import { ConfigProvider } from "./contexts/configContext"
import Homepage from "./components/Home/Homepage"
import { Hostname } from "./components/Hostname/Hostname"
import ErrorPage from "./components/ErrorPage/ErrorPage"
import DebugLayout from "./DebugLayout"
import Monitoring from "./components/Monitoring/Monitoring"
import KPI from "./components/KPI/KPI"
import Tdr from "./components/TDR/Tdr"
import Dashboard from "./components/Dashboard/Dashboard"
import Login from "./components/Login/Login"
import { uuidFromCode } from "./utils"
import { LoginProvider, useLogin } from "./contexts/loginContext"

const App = (props) => {
  const login = useLogin()
  const navigate = useNavigate()

  const routes = [
    { route: "/", Component: Homepage, title: "HOMEPAGE" },
    { route: "/routing", Component: Routing, title: "ROUTING" },
    { route: "/home", Component: Homepage, title: "HOMEPAGE" },
    { route: "/hostname", Component: Hostname, title: "HOSTNAME" },
    { route: "/kpi", Component: KPI, title: "KPI" },
    { route: "/monitoring", Component: Monitoring, title: "MONITORING" },
    { route: "/log", Component: Tdr, title: "LOG" },
    { route: "/dashboard", Component: Dashboard, title: "DASHBOARD" },
  ]

  const wrapRouteWithLayout = ({ route, Component, title }) => {
    return (
      <Route
        key={title}
        path={route}
        element={
          <Layout title={title}>
            <Component {...props} />
          </Layout>
        }
        exact
      />
    )
  }

  return (
    <SnackbarProvider>
      <ConfigProvider>
        <LoginProvider>
          <Routes>
            {/* Rotta "/login" fuori dal Layout */}
            <Route path="/login" element={<Login />} />

            {/* Tutte le altre rotte avvolte dal Layout */}
            {routes.map((route) => wrapRouteWithLayout(route))}

            {/* Pagina 404 */}
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </LoginProvider>
      </ConfigProvider>
    </SnackbarProvider>
  )
}

export default withErrorBoundary(App)
//<Route path='/groups' render={(props) => <ProtectionGroups {...props} setBreadcrumb={breadcrumbCallback} />} exact />
