import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { v4 as uuidv4 } from "uuid";
import Form from "react-bootstrap/Form";
import _ from "lodash";
import { isNonEmptyString, uuidFromCode } from "../../utils";

const AddRouteModal = (props) => {
  const emptyRoute = {
    mfw: "",
    direction: "",
    dialogue_mode: "",
    domain_name: "",
    id: uuidv4(),
  };

  const [direction, setdirection] = useState([]);
  const [dialogueMode, setdialogueMode] = useState();
  const [route, setroute] = useState(emptyRoute);
  const [domains, setdomains] = useState(props.domains || []);
  const originalDomains = props.domains;
  const routes = props.routes || [];
  const whitelist = props.whitelist || [];
  const mfw = ["ApiRoot", "TelescopicFQDN"];
  const dialogueModePossibleValues = ["inbound", "outbound"];

  const addRoute = () => {
    props.addRoute(route);
    props.onHide();
    setroute(emptyRoute);
  };

  useEffect(() => {
    if (originalDomains.length > 0) {
      originalDomains.find((d) => d.is_hub)
        ? setdirection(["Direct", "Hub"])
        : setdirection(["Direct"]);
      if (routes) {
        if (dialogueMode === "inbound")
          setdomains(
            originalDomains.filter(
              (d) => !routes.find((r) => d.domain_name === r.domain_name)
            )
          );
        else if (whitelist && dialogueMode === "outbound") {
          setdomains(
            originalDomains.filter(
              (d) => !whitelist.find((w) => w.domain_name === d.domain_name)
            )
          );
        }
      }
    }
  }, [dialogueMode]);

  const handleRD = (e) => {
    const tmpRoute = { ...route };
    tmpRoute.domain_name = e.target.value;
    setroute(tmpRoute);
  };

  const handleDM = (e) => {
    const tmpRoute = { ...route };
    tmpRoute.dialogue_mode = e.target.value;
    setdialogueMode(e.target.value);
    setroute(tmpRoute);
  };

  const handleD = (e) => {
    const tmpRoute = { ...route };
    tmpRoute.direction = e.target.value;
    setroute(tmpRoute);
  };

  const handleMFW = (e) => {
    const tmpRoute = { ...route };
    tmpRoute.mfw = e.target.value;
    setroute(tmpRoute);
  };

  return (
    <Modal size="m" show={props.show} dialogClassName="addModal text-center">
      <Modal.Header className="d-flex justify-content-center ">
        <Modal.Title>Add Route</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="flex-grow-1 form-group w-100">
            <Form.Select defaultValue="label" onChange={(e) => handleDM(e)}>
              <option value="label" disabled>
                Direction
              </option>
              {dialogueModePossibleValues.map((g) => {
                return (
                  <option key={() => uuidFromCode()} value={g}>
                    {g}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
          <Form.Group className="flex-grow-1 form-group w-100 ">
            <Form.Select defaultValue="label" onChange={(e) => handleRD(e)}>
              <option value="label" disabled>
                Remote Domain
              </option>
              {domains &&
                domains.length > 0 &&
                domains.map((g) => {
                  return (
                    <option key={() => uuidFromCode()} value={g.domain_name}>
                      {g.domain_name}
                    </option>
                  );
                })}
            </Form.Select>
          </Form.Group>

          {route.dialogue_mode === "inbound" && (
            <Form.Group className="flex-grow-1 form-group w-100">
              <Form.Select defaultValue="label" onChange={(e) => handleD(e)}>
                <option value="label" disabled>
                  Dialogue Mode
                </option>
                {direction.map((g) => {
                  return (
                    <option key={() => uuidFromCode()} value={g}>
                      {g}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
          )}
          {route.dialogue_mode === "inbound" && (
            <Form.Group className="flex-grow-1 form-group w-100">
              <Form.Select defaultValue="label" onChange={(e) => handleMFW(e)}>
                <option value="label" disabled>
                  Mfw
                </option>
                {mfw.map((g) => {
                  return (
                    <option key={() => uuidFromCode()} value={g}>
                      {g}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={
            dialogueMode === "inbound" &&
            Object.keys(route).filter((key) => isNonEmptyString(route[key]))
              .length < Object.keys(route).length
              ? { visibility: "hidden" }
              : dialogueMode === "outbound" &&
                (!isNonEmptyString(route.domain_name) ||
                  !isNonEmptyString(route.dialogue_mode))
              ? { visibility: "hidden" }
              : !isNonEmptyString(dialogueMode)
              ? { visibility: "hidden" }
              : { visibility: "unset" }
          }
          onClick={() => addRoute()}
        >
          Add
        </Button>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddRouteModal;
