import React, { useState, useEffect } from "react"
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"
import "./Hostname.less"
import { isNonEmptyString, uuidFromCode, isNonEmptyArray } from "../../utils"
import CrossedInput from "../SharedComponents/CrossedInput"
import { OverlayTrigger, Tooltip } from "react-bootstrap"

const AddHostnameModal = (props) => {
  const [hostname, sethostname] = useState({
    hostname: "",
    address: "",
    id: uuidFromCode(),
    type: "Manual",
    nfType: "",
  })

  const [formFilled, setformFilled] = useState(false)
  const [errors, seterrors] = useState([])

  // const ipAddressRegex = /\d+\.\d+\.\d+\.\d+(:\d+)?/gm
  // const admittedCharRegex = /^[0-9.:]/gm

  useEffect(() => {
    if (formFilled) {
      props.addHostname(hostname)
      props.onHide()
      sethostname({
        hostname: "",
        address: "",
        id: uuidFromCode(),
        type: "Manual",
        nfType: "",
      })
      setformFilled(false)
    }
  }, [formFilled])

  const checkForErrors = () => {
    const errorArray = []
    if (!isNonEmptyString(hostname.hostname)) errorArray.push("The Hostname field is mandatory")
    if (!isNonEmptyString(hostname.address)) errorArray.push("The Address field is mandatory")
    if (hostname.address.startsWith("http://") || hostname.address.startsWith("https://"))
      errorArray.push('The Address field must not contain "http://" nor "https://"')
    if (!isNonEmptyArray(errorArray)) setformFilled(true)
    seterrors(errorArray)
  }

  const handleName = (e) => {
    const tmpHostname = { ...hostname }
    tmpHostname.hostname = e.target.value
    sethostname(tmpHostname)
  }

  const handleAddress = (e) => {
    const tmpHostname = { ...hostname }
    tmpHostname.address = e.target.value
    sethostname(tmpHostname)
  }

  const handleNFType = (e) => {
    const tmpHostname = { ...hostname }
    tmpHostname.nfType = e.target.value
    sethostname(tmpHostname)
  }

  return (
    <Modal size="m" show={props.show} dialogClassName="addModal text-center">
      <Modal.Header>
        <Modal.Title>Add Hostname</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="data-row">
          <span>
            Hostname:<span className="mandatory-field"> *</span>
          </span>
          <CrossedInput placeholder="Name" value={hostname.hostname} object={hostname} callback={handleName} />
        </div>
        <OverlayTrigger overlay={<Tooltip style={{ position: "absolute" }}> Ip addresses (without http://)</Tooltip>}>
          <div className="data-row">
            <span>
              Address:<span className="mandatory-field"> *</span>
            </span>
            <CrossedInput
              placeholder="IP Address"
              value={hostname.address}
              object={hostname}
              callback={handleAddress}
            />
          </div>
        </OverlayTrigger>
        <div className="data-row">
          <span>NF Type:</span>
          <CrossedInput placeholder="Type" value={hostname.nfType} object={hostname} callback={handleNFType} />
        </div>
        {isNonEmptyArray(errors) && (
          <div className="upload-errors mt-3">
            <span className="errors-title">Some errors have been found!</span>
            {errors.map((err) => (
              <div>- {err}.</div>
            ))}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => checkForErrors()}>Add</Button>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default AddHostnameModal
