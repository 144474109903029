import React, { useState, useEffect } from "react"
import { Button } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { useLogin } from "../../contexts/loginContext"
import { useSnackbar } from "../../contexts/snackbarContext"
import { customizeToast, isNonEmptyString } from "../../utils"
import toast, { Toaster } from "react-hot-toast"
import { useConfig } from "../../contexts/configContext"

const Login = () => {
  const [password, setpassword] = useState("")
  const [error, seterror] = useState(null)
  const navigate = useNavigate()
  const login = useLogin()
  const snackbar = useSnackbar()
  const config = useConfig()

  useEffect(() => {
    if (!login.isUserLogged && isNonEmptyString(login.notValidTokenMessage)) {
      snackbar.setSnackbarInfo({ status: "warning", message: login.notValidTokenMessage })
    }
  }, [])

  const verifyToken = async () => {
    const url = `${config.configInfo.authUrl}`
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${password}`, // Inserisci qui il token
      },
    })
    if (!response.ok) {
      const json = await response.json()
      console.error(json)
      login.setisUserLogged(false)
      snackbar.setSnackbarInfo({
        status: "error",
        message: "The token you provided has expired or is not valid. Please sign in with a valid token.",
      })
    } else {
      snackbar.setSnackbarInfo(null)
      localStorage.setItem("Token", password)
      login.setisUserLogged(true)
      navigate("/home")
    }
  }

  useEffect(() => {
    if (snackbar && snackbar.snackbarInfo && snackbar.snackbarInfo.status !== "") {
      customizeToast(snackbar, toast)
    }
  }, [snackbar])

  return (
    <>
      <Toaster gutter={24} />
      <div className="login-page colDiv j-center">
        <div className="rowDiv j-between" style={{ width: "40vw" }}>
          <h1 style={{ color: "white" }}>LOGIN</h1>
          <img src="/Logo.png" width="174px" height="36px"></img>
        </div>
        <div className="colDiv j-center" style={{ height: "20vh", width: "40vw", border: "solid white 1px", backgroundColor: "#32494c" }}>
          <div className="rowDiv j-start">
            <h5 style={{ color: "white", marginRight: "10px" }}>Token:</h5>
            <input type="password" style={{ width: "350px" }} onChange={(e) => setpassword(e.target.value)} />
            <Button style={{ marginLeft: "10px" }} onClick={verifyToken}>
              Send
            </Button>
          </div>
        </div>
        {error && (
          <div className="upload-errors" style={{ marginTop: "20px", marginLeft: "0px", width: "500px" }}>
            {error}
          </div>
        )}
      </div>
    </>
  )
}

export default Login
