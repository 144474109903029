import { faClose } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { forwardRef, useImperativeHandle, useRef, useState } from "react"
import { Button, Form, InputGroup } from "react-bootstrap"
import { isNonEmptyString } from "../../utils"

const CrossedInput = forwardRef((props, ref) => {
  const [value, setvalue] = useState(props.value || null)
  const [highlight, sethighlight] = useState(null)
  // const inputRef = useRef()

  // useImperativeHandle(ref, () => ({
  //   focus() {
  //     console.log("focus")
  //     inputRef.current.focus()
  //   },
  //   blur() {
  //     console.log("blur")
  //     inputRef.current.blur()
  //   },
  // }))

  const checkInput = (e) => {
    if (props.admittedCharRegex) {
      const text = e.target.value
      const textToArray = Array.from(text)
      const textLength = textToArray.length
      const lastChar = text[textLength - 1]
      console.log(lastChar)
      if (isNonEmptyString(lastChar) && !props.admittedCharRegex.test(lastChar)) {
        e.preventDefault()
        return
      }
    }

    if (props.required && !isNonEmptyString(e.target.value)) sethighlight(true)
    else sethighlight(false)
    const obj = { ...props.object }
    obj[props.value] = e.target.value
    setvalue(e.target.value)
    props.callback && props.callback(e)
  }

  return (
    <InputGroup style={{ width: props.size || "100%" }}>
      <Form.Control
        ref={ref}
        required={props.required || false}
        style={{
          ...props.style,
          width: props.size || "80%",
          maxWidth: "100%",
          ...(highlight ? { borderBottom: "3px solid red", opacity: 0.2 } : {}),
        }}
        type="text"
        value={props.value || value || ""}
        placeholder={props.placeholder}
        disabled={props.inputDisabled}
        onChange={(e) => {
          checkInput(e)
        }}
        onKeyDown={(e) => {
          if (props.regex) {
            e.key.match(props.regex) && e.preventDefault()
          }
        }}
      />
      {!props.noClear && (
        <Button
          className="form-control-clear"
          variant="outline-secondary"
          disabled={props.clearDisabled}
          onClick={() => {
            const e = { target: { value: "" } }
            props.callback(e)
            setvalue("")
          }}
        >
          <FontAwesomeIcon icon={faClose}></FontAwesomeIcon>
        </Button>
      )}
    </InputGroup>
  )
})

export default CrossedInput
