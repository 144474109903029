import React from "react"
import "./NavMenu.less"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faHome,
  faSignOutAlt,
  faUserAlt,
  faList,
  faDesktop,
  faDiagramProject,
  faCodeCompare,
  faRightLeft,
  faChartLine,
} from "@fortawesome/free-solid-svg-icons"
import { useNavigate } from "react-router-dom"
import { useConfig } from "../contexts/configContext"
import { useLogin } from "../contexts/loginContext"

const Nav = (props) => {
  const login = useLogin()
  const navigate = useNavigate()
  const config = useConfig()

  const logout = () => {
    console.log(document.cookie)
    document.cookie = "auth_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"
    console.log(document.cookie)
    localStorage.removeItem("Token")
    login.setisUserLogged(false)
    navigate("/login")
  }

  return (
    <>
      <div className="menu">
        <h3 className="title">SEPP {config?.configInfo?.is_hub ? "HUB" : ""}</h3>
        <h6 className="subtitle">{config?.configInfo?.tenant || "test"}</h6>
        <div className="colDiv j-between" style={{ height: "88%" }}>
          <div style={{ width: "100%" }}>
            <div className="action" style={{ marginTop: "50px" }}>
              <a href="/home">
                {" "}
                <FontAwesomeIcon icon={faHome}></FontAwesomeIcon>
                <div>Home</div>
              </a>
            </div>
            <div className="action">
              <a href="/routing">
                {" "}
                <FontAwesomeIcon icon={faDiagramProject}></FontAwesomeIcon>
                <div>Routing</div>
              </a>
            </div>
            <div className="action">
              <a href="/hostname">
                {" "}
                <FontAwesomeIcon icon={faCodeCompare}></FontAwesomeIcon>
                <div>
                  <div
                    style={{
                      fontSize: "11pt",
                      marginTop: "-10px",
                      background: "transparent",
                    }}
                  >
                    Service Communication Proxy
                  </div>
                </div>
              </a>
            </div>
            <div className="action">
              <a href="/kpi">
                {" "}
                <FontAwesomeIcon icon={faList}></FontAwesomeIcon>
                <div>KPI</div>
              </a>
            </div>
            <div className="action">
              <a href="/monitoring">
                {" "}
                <FontAwesomeIcon icon={faDesktop}></FontAwesomeIcon>
                <div>Monitoring</div>
              </a>
            </div>
            <div className="action">
              <a href="/log">
                {" "}
                <FontAwesomeIcon icon={faRightLeft} />
                <div>Log</div>
              </a>
            </div>
            <div className="dashboard">
              <a href="/dashboard">
                {" "}
                <FontAwesomeIcon icon={faChartLine} />
                <div>Dashboard</div>
              </a>
            </div>
            {/* <LinkContainer
          to={'/home'}
          className='action'
          onClick={(e) => console.log(e)}
        >
          <a href='#'>
            {' '}
            <FontAwesomeIcon icon={faHome}></FontAwesomeIcon>
            <div>Home</div>
          </a>
        </LinkContainer>
        <LinkContainer
          to={'/routing'}
          className='action'
          onClick={() => navigate('/routing')}
        >
          <a href='#'>
            {' '}
            <FontAwesomeIcon icon={faList}></FontAwesomeIcon>
            <div>Routing</div>
          </a>
        </LinkContainer>
        <LinkContainer
          to={'/hostname'}
          className='action'
          onClick={(e) => console.log(e)}
        >
          <a href='#'>
            {' '}
            <FontAwesomeIcon icon={faDesktop}></FontAwesomeIcon>
            <div>Hostname</div>
          </a>
        </LinkContainer> */}
          </div>
          <div style={{ width: "100%" }}>
            <div className="action">
              <a onClick={logout}>
                <FontAwesomeIcon icon={faUserAlt}></FontAwesomeIcon>
                <FontAwesomeIcon icon={faSignOutAlt}></FontAwesomeIcon>
                <div>Logout</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { Nav as NavMenu }
