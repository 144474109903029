import "bootstrap/dist/css/bootstrap.css"
import "./index.css"
import React from "react"
import { createRoot } from "react-dom/client"
import { BrowserRouter } from "react-router-dom"

import App from "./App"
import * as registerServiceWorker from "./registerServiceWorker"

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href") || ""

const container = document.getElementById("root")
// Create a root.
const root = createRoot(container)

root.render(
  <BrowserRouter basename={baseUrl}>
    <App />
  </BrowserRouter>
)

registerServiceWorker.register()

// registerServiceWorker();
