import parse from "html-react-parser"
import Fade from "react-bootstrap/Fade"

export const formatDate = (date) => {
  console.log(date)
  date = "2023-01-05T13:22:27.002Z"
  const intlDate = new Intl.DateTimeFormat("en-US", {
    hour12: false,
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZoneName: "shortOffset",
  }).format(new Date(date))

  // 04/21/2023, 17:28:45 GMT+2

  //2023-04-26 15:55:48 GMT+2

  const month = intlDate.split("/")[0]
  const day = intlDate.split("/")[1]
  const year = intlDate.split("/")[2].split(",")[0]
  const time = intlDate.split(", ")[1]
  console.log(`date: ${date}, intlDate: ${intlDate}, formatted: ${year}-${month}-${day} ${time}`)
  return `${year}-${month}-${day} ${time}`
}

export const isNonEmptyString = (value) => {
  return value != null && typeof value === "string" && value !== ""
}

export const isNonEmptyNumber = (value) => {
  return value != null && typeof value === "number" && value !== "" && !isNaN(value)
}

export const isNonEmptyArray = (value) => {
  return value != null && typeof value === "object" && value.length > 0
}

export const isNonEmptyObject = (value) => {
  return value != null && typeof value === "object" && JSON.stringify(value) !== "{}"
}

export const uuidFromCode = () => {
  const newUuid = ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
  )
  return newUuid
}

export const removeUselessField = (key, array) => {
  array.forEach((el) => {
    if (el.hasOwnProperty(key)) delete el[key]
  })
  return array
}

export const removeAllButFields = (keysToKeep, data) => {
  data.forEach((el) => {
    return Object.keys(el).map((key) => {
      if (!keysToKeep.includes(key.toLowerCase())) delete el[key]
      return el
    })
  })
  return data
}

export const customizeToast = (snackbar, toast) => {
  const fadeInStyle = {
    borderRadius: "10px",
    padding: "8px",
    boxShadow: "10px 10px 8px black",
    maxWidth: "40%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    border: "4px solid transparent",
  }

  switch (snackbar.snackbarInfo.status) {
    case "success":
      toast.custom(
        (t) => (
          <Fade in={true} appear={true}>
            <div
              style={{
                ...fadeInStyle,
                background: "linear-gradient(#e6ffcc, #e6ffcc) padding-box,linear-gradient(to right, #afc283, #5a7900) border-box",
              }}
            >
              <div
                style={{
                  width: "10%",
                  marginLeft: "10px",
                  fontSize: "16pt",
                }}
              >
                ✅
              </div>
              {/* <div
                    style={{
                      height: "100%",
                      width: "1px",
                      border: "2px solid #4cab55",
                      borderRadius: "15px",
                      marginLeft: "30px",
                    }}
                  ></div> */}
              <div style={{ marginLeft: "10px" }}>{parse(snackbar.snackbarInfo.message)}</div>
            </div>
          </Fade>
        ),
        {
          duration: snackbar.snackbarInfo.duration || 6000,
        }
      )
      break
    case "warning":
      toast.custom(
        (t) => (
          <Fade in={true} appear={true}>
            <div
              style={{
                ...fadeInStyle,
                backgroundColor: "#ffe6b3",
                background: "linear-gradient(#ffe6b3, #ffe6b3) padding-box,linear-gradient(to right, #f6c574, #e99328) border-box",
              }}
            >
              <div
                style={{
                  width: "10%",
                  marginLeft: "10px",
                  fontSize: "16pt",
                }}
              >
                ⚠️
              </div>
              {/* <div
                    style={{
                      height: "100%",
                      width: "1px",
                      border: "2px solid #f3b669",
                      borderRadius: "15px",
                    }}
                  ></div> */}

              <div style={{ marginLeft: "10px" }}>{parse(snackbar.snackbarInfo.message)}</div>
            </div>
          </Fade>
        ),
        {
          duration: snackbar.snackbarInfo.duration || 6000,
        }
      )
      break
    case "error":
      // toast.error(snackbar.snackbarInfo.message, {
      //   duration: 6000,
      //   style: {
      //     backgroundColor: '#ffc2b3',
      //     border: '1px solid #ff8566',
      //     borderRadius: '5px',
      //     padding: '8px',
      //     boxShadow: '10px 10px 8px #454545',
      //   },
      // })
      toast.custom(
        (t) => (
          <Fade in={true} appear={true}>
            <div
              style={{
                ...fadeInStyle,
                background: "linear-gradient(#ffc2b3, #ffc2b3) padding-box,linear-gradient(to right, #c28383, #790000) border-box",
              }}
            >
              <div
                style={{
                  width: "10%",
                  marginLeft: "10px",
                  fontSize: "16pt",
                }}
              >
                ❌
              </div>
              {/* <div
                    style={{
                      height: "100%",
                      width: "1px",
                      border: "2px solid #790000",
                      borderRadius: "15px",
                    }}
                  ></div> */}
              <div style={{ marginLeft: "10px" }}>{parse(snackbar.snackbarInfo.message)}</div>
            </div>
          </Fade>
        ),
        {
          duration: snackbar.snackbarInfo.duration || 6000,
        }
      )
      break
    case "info":
      toast.custom(
        (t) => (
          <Fade in={true} appear={true}>
            <div
              style={{
                ...fadeInStyle,
                background: "linear-gradient(#d3eaf2, #d3eaf2) padding-box,linear-gradient(to right, #83b1c2, #005879) border-box",
              }}
            >
              <div
                style={{
                  width: "10%",
                  marginLeft: "10px",
                  fontSize: "16pt",
                }}
              >
                ℹ️
              </div>
              {/* <div
                    style={{
                      height: "100%",
                      width: "1px",
                      border: "2px solid #65b1e9",
                      borderRadius: "15px",
                    }}
                  ></div> */}
              <div style={{ marginLeft: "10px" }}>{parse(snackbar.snackbarInfo.message)}</div>
            </div>
          </Fade>
        ),
        {
          duration: snackbar.snackbarInfo.duration || 6000,
        }
      )
      break
  }
}
