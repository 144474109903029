import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import { useNavigate } from 'react-router-dom'

const withErrorBoundary = (WrappedComponent) => {
  return function ErrorBoundary(props) {
    const [error, setError] = useState(null)
    const navigate = useNavigate()

    if (error) {
      return (
        <div className='jumbotron'>
          <h2 className='centerbox  middle-align'>
            Oops, there was an error :( &nbsp;&nbsp;&nbsp;&nbsp;
            <Button onClick={navigate('/services')}>Return to Home</Button>
          </h2>
        </div>
      )
    }

    try {
      return <WrappedComponent {...props} />
    } catch (err) {
      setError(err)
    }
  }
}

export default withErrorBoundary
