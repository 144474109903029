import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import FormGroup from "react-bootstrap/FormGroup";
import FormControl from "react-bootstrap/FormControl";
import FormSelect from "react-bootstrap/FormSelect";
import Form from "react-bootstrap/Form";
import "./Routing.less";
import { v4 as uuidv4 } from "uuid";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  isNonEmptyNumber,
  isNonEmptyString,
  isNonEmptyArray,
  uuidFromCode,
} from "../../utils";
import CrossedInput from "../SharedComponents/CrossedInput";
import { useConfig } from "../../contexts/configContext";

const AddDomainModal = (props) => {
  const emptyDomain = {
    domain_name: "",
    dns: "",
    is_hub: false,
    address_1: "",
    address_2: "",
    address_3: "",
    address_4: "",
    sni: "",
    id: uuidv4(),
    addresses: [],
    mode: 0,
    addressMode: "Round-Robin",
    deleteable: true,
  };
  const [formFilled, setformFilled] = useState(false);
  const [formErrors, setformErrors] = useState([]);
  const [domain, setdomain] = useState(emptyDomain);
  const config = useConfig();
  const seppDomain = config.configInfo.dnsName;
  const namespace = config.namespace;
  const readableMode = [
    { field: "Round-Robin", value: 0 },
    { field: "Primary/Secondary", value: 1 },
  ];
  const mandatoryFields = ["domain_name", "dns", "address_1", "sni"];
  const addressRegex = /https?:\/\/[a-zA-Z0-9.\-?=&_:]+\//g;
  const admittedCharRegex = /[a-zA-Z0-9.\-?=&_:\/]/g;

  // const addDomain = () => {
  //   props.addDomain(domain)
  //   props.onHide()
  // }

  useEffect(() => {
    if (formFilled) {
      props.addDomain(domain);
      props.onHide();
      setdomain(emptyDomain);
      setformFilled(false);
    }
  }, [formFilled]);

  const checkMode = (tmpDomain) => {
    tmpDomain.mode = isNonEmptyNumber(tmpDomain.mode) ? tmpDomain.mode : 0;
    return tmpDomain;
  };

  const checkExistingAddress = (value, name) => {
    if (!value.match(addressRegex)) {
      return `You must provide a valid address on ${name}`;
    }
    if (
      props.domains.find((d) => {
        console.log(d, value);
        return (
          d.address_1 === value ||
          d.address_2 === value ||
          d.address_3 === value ||
          d.address_4 === value
        );
      })
    )
      return `The ${name} already exists. Please change it to a new one`;
    return "";
  };

  //comment

  const checkFormFields = () => {
    const tmpDomain = { ...domain };
    const errors = new Set();
    Object.keys(tmpDomain).forEach((key) => {
      if (mandatoryFields.includes(key)) {
        if (key === "domain_name") {
          if (
            props.domains.find((d) => d.domain_name === tmpDomain.domain_name)
          )
            errors.add(
              `This Node already exists. Please change it to a new one`
            );
          !isNonEmptyString(tmpDomain.domain_name) &&
            errors.add(`Node Name cannot be empty`);
          if (tmpDomain.domain_name === namespace)
            errors.add(`The 'Node Name' cannot be the same of the SEPP itself`);
        }
        if (key === "dns") {
          if (
            props.domains.find((d) => d.dns === tmpDomain.dns) ||
            tmpDomain.dns === seppDomain
          )
            errors.add(
              `This Domain already exists. Please change it to a new one`
            );
          !isNonEmptyString(tmpDomain.dns) &&
            errors.add(`Domain cannot be empty`);
        }
        if (key === "address_1") {
          !isNonEmptyString(tmpDomain[key]) &&
            errors.add(`The first Address field cannot be empty`);
          errors.add(checkExistingAddress(tmpDomain[key], "first Address"));
          if (
            tmpDomain[key] === tmpDomain.address_2 ||
            tmpDomain[key] === tmpDomain.address_3 ||
            tmpDomain[key] === tmpDomain.address_4
          )
            errors.add(`You cannot add the same Address more than one time`);
        }
        if (key === "sni") {
          !isNonEmptyString(tmpDomain.sni) &&
            errors.add(`The SNI field cannot be empty`);
        }
      }
      if (key === "address_2" && isNonEmptyString(tmpDomain[key])) {
        if (
          tmpDomain[key] === tmpDomain.address_3 ||
          tmpDomain[key] === tmpDomain.address_4
        )
          errors.add(`You cannot add the same Address more than one time`);
        errors.add(checkExistingAddress(tmpDomain[key], "second Address"));
      }
      if (key === "address_3" && isNonEmptyString(tmpDomain[key])) {
        if (tmpDomain[key] === tmpDomain.address_4)
          errors.add(`You cannot add the same Address more than one time`);
        errors.add(checkExistingAddress(tmpDomain[key], "third Address"));
      }
      if (key === "address_4" && isNonEmptyString(tmpDomain[key])) {
        errors.add(checkExistingAddress(tmpDomain[key], "fourth Address"));
      }
    });
    const array = Array.from(errors).filter((el) => isNonEmptyString(el));
    if (array.length === 0) {
      tmpDomain.addresses = [
        tmpDomain.address_1,
        tmpDomain.address_2,
        tmpDomain.address_3,
        tmpDomain.address_4,
      ].filter((el) => isNonEmptyString(el));
      setdomain(tmpDomain);
      setformFilled(true);
    }
    setformErrors(array);
  };

  const handleName = (e) => {
    const tmpDomain = { ...domain };
    tmpDomain.domain_name = e.target.value;
    setdomain(tmpDomain);
  };

  const handleDns = (e) => {
    const tmpDomain = { ...domain };
    tmpDomain.dns = e.target.value;
    setdomain(tmpDomain);
  };

  const handleIsHub = (e) => {
    const tmpDomain = { ...domain };
    tmpDomain.is_hub = e.target.checked;
    setdomain(tmpDomain);
  };

  const handleaddress_1 = (e) => {
    const tmpDomain = { ...domain };
    tmpDomain.address_1 = e.target.value;
    setdomain(tmpDomain);
  };

  const handleaddress_2 = (e) => {
    const tmpDomain = { ...domain };
    tmpDomain.address_2 = e.target.value;
    setdomain(tmpDomain);
  };

  const handleaddress_3 = (e) => {
    const tmpDomain = { ...domain };
    tmpDomain.address_3 = e.target.value;
    setdomain(tmpDomain);
  };

  const handleaddress_4 = (e) => {
    const tmpDomain = { ...domain };
    tmpDomain.address_4 = e.target.value;
    setdomain(tmpDomain);
  };

  const handleSni = (e) => {
    const tmpDomain = { ...domain };
    tmpDomain.sni = e.target.value;
    setdomain(tmpDomain);
  };

  const handleMode = (e) => {
    const tmpDomain = { ...domain };
    tmpDomain.mode = parseInt(e.target.value);
    tmpDomain.addressMode = readableMode[parseInt(e.target.value)].field;
    setdomain(tmpDomain);
  };

  console.log(props.domains);

  return (
    <Modal size="m" show={props.show} dialogClassName="addModal text-center">
      <Modal.Header className="d-flex justify-content-center ">
        <Modal.Title>Add Remote Domain</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {isNonEmptyArray(props.domains) &&
          !props.domains.find((d) => d.is_hub) ? (
            <Form.Check
              style={{ textAlign: "left" }}
              name="isHub"
              value=""
              id="isHub"
              label="IsHub"
              onChange={(e) => handleIsHub(e)}
            />
          ) : !isNonEmptyArray(props.domains) ? (
            <Form.Check
              style={{ textAlign: "left" }}
              name="isHub"
              value=""
              id="isHub"
              label="IsHub"
              onChange={(e) => handleIsHub(e)}
            />
          ) : null}
          <div className="data-row">
            <span>
              Node Name:<span className="mandatory-field"> *</span>
            </span>
            <CrossedInput
              placeholder="Name"
              value={domain.domain_name}
              object={domain}
              callback={handleName}
            />
          </div>

          <OverlayTrigger
            overlay={
              <Tooltip style={{ position: "absolute" }}>
                {" "}
                Must be a new domain
              </Tooltip>
            }
          >
            <div className="data-row">
              <span>
                Domain:<span className="mandatory-field"> *</span>
              </span>
              <CrossedInput
                placeholder="Dns"
                value={domain.dns}
                object={domain}
                callback={handleDns}
              />
            </div>
          </OverlayTrigger>

          <OverlayTrigger
            overlay={
              <Tooltip style={{ position: "absolute" }}>
                {" "}
                Ip addresses must begin with http:// or https:// and must end
                with /
              </Tooltip>
            }
          >
            <div className="data-row">
              <span>
                Address:<span className="mandatory-field"> *</span>
              </span>
              <CrossedInput
                admittedCharRegex={admittedCharRegex}
                placeholder="Address"
                value={domain.address_1}
                object={domain}
                callback={handleaddress_1}
              />
            </div>
          </OverlayTrigger>

          <OverlayTrigger
            overlay={
              <Tooltip style={{ position: "absolute" }}>
                {" "}
                Ip addresses must begin with http:// or https:// and must end
                with /
              </Tooltip>
            }
          >
            <div className="data-row">
              <span>Address:</span>
              <CrossedInput
                placeholder="Address"
                admittedCharRegex={admittedCharRegex}
                value={domain.address_2}
                object={domain}
                callback={handleaddress_2}
              />
            </div>
          </OverlayTrigger>

          <OverlayTrigger
            overlay={
              <Tooltip style={{ position: "absolute" }}>
                {" "}
                Ip addresses must begin with http:// or https:// and must end
                with /
              </Tooltip>
            }
          >
            <div className="data-row">
              <span>Address:</span>
              <CrossedInput
                placeholder="Address"
                admittedCharRegex={admittedCharRegex}
                value={domain.address_3}
                object={domain}
                callback={handleaddress_3}
              />
            </div>
          </OverlayTrigger>

          <OverlayTrigger
            overlay={
              <Tooltip style={{ position: "absolute" }}>
                {" "}
                Ip addresses must begin with http:// or https:// and must end
                with /
              </Tooltip>
            }
          >
            <div className="data-row">
              <span>Address:</span>
              <CrossedInput
                placeholder="Address"
                admittedCharRegex={admittedCharRegex}
                value={domain.address_4}
                object={domain}
                callback={handleaddress_4}
              />
            </div>
          </OverlayTrigger>

          <div className="data-row">
            <span>
              SNI:<span className="mandatory-field"> *</span>
            </span>

            <CrossedInput
              placeholder="sepp1.5gc"
              value={domain.sni}
              object={domain}
              callback={handleSni}
            />
          </div>

          <FormGroup className="flex-grow-1 form-group w-100">
            <FormSelect
              style={{ textAlign: "left" }}
              defaultValue="label"
              onChange={(e) => handleMode(e)}
            >
              <option value="label" disabled>
                Address Mode
              </option>
              {readableMode.map((mode) => {
                const uuid = uuidFromCode();
                return (
                  <option key={uuid} value={mode.value}>
                    {mode.field}
                  </option>
                );
              })}
            </FormSelect>
          </FormGroup>

          {isNonEmptyArray(formErrors) && (
            <div className="upload-errors mt-3">
              <span className="errors-title">Some errors have been found!</span>
              {formErrors.map((err) => (
                <div>- {err}.</div>
              ))}
            </div>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          // style={formFilled && !isNonEmptyArray(formErrors) ? {} : { visibility: "hidden" }}
          onClick={() => {
            checkFormFields();
          }}
        >
          Add
        </Button>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddDomainModal;
